import React from 'react'
import styles from './services.module.sass'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Service from '../components/service'
import { Link } from 'gatsby'

// Services Images
import serviceWealthPreservation from '../assets/images/services/wealth-preservation.png'
import serviceCollegeFunding from '../assets/images/services/college-funding.png'
import serviceAssetAllocation from '../assets/images/services/asset-allocation.png'
import servicePlannedGifting from '../assets/images/services/planned-gifting.png'
import serviceLegacyPlanning from '../assets/images/services/legacy-planning.png'
import serviceRetirementPlanning from '../assets/images/services/retirement-planning.png'
import serviceSuccessionPlanning from '../assets/images/services/succession-planning.png'
import serviceIncomeTaxPlanning from '../assets/images/services/income-tax-planning.png'
import serviceEstateTaxPlanning from '../assets/images/services/estate-tax-planning.png'
import serviceRiskManagement from '../assets/images/services/risk-management.png'
import servicePortfolioManagement from '../assets/images/services/portfolio-management.png'
import serviceSecondOpinion from '../assets/images/services/second-opinion.png'

const ServicesPage = () => (
   <Layout>
      <SEO title="Our Services" />
      <div className={['page', styles.page__services].join(' ')}>
         <div className={['page--header', styles.page__header].join(' ')}>
            <h1 className={['page--title', styles.page__title].join(' ')}>
               Our Services
            </h1>
            <p
               className={['page--subheader', styles.page__subheader].join(
                  ' '
               )}>
               We integrate each financial portfolio with an effective
               human-centric estate + tax plan, <br></br> designed to help maximize each
               individual's wealth potential.
            </p>
         </div>
         <div className={styles.services}>
            <Service
               image={serviceWealthPreservation}
               name="Wealth Preservation"
               description="Strategies and techniques for preserving wealth for community, spouses, and heirs."
            />
            <Service
               image={serviceCollegeFunding}
               name="College Funding"
               description="Utilizing different methods for accumulating funds and paying for college expenses."
            />
            <Service
               image={serviceAssetAllocation}
               name="Asset Allocation"
               description="An extremely important process to help ensure the portfolio allocation is aligned with risk tolerance and is tax-efficient."
            />
            <Service
               image={servicePlannedGifting}
               name="Planned Gifting"
               description="A systematic strategy to help pass wealth onto heirs in a tax-efficient manner."
            />
            <Service
               image={serviceLegacyPlanning}
               name="Legacy Planning"
               description="Arranging your financial affairs to help accomplish your personal objectives for your heirs."
            />{' '}
            <Service
               image={serviceIncomeTaxPlanning}
               name="Income Tax Planning"
               description="Tax laws evolve every year. It is important to ensure that your income tax planning incorporates these changes."
               needsTaxDisclaimer="true"
            />
            <Service
               image={servicePortfolioManagement}
               name="Portfolio Management"
               description="Within a portfolio allocation, specific decisions need to be made to help manage risk, income tax liability, and other financial considerations."
            />{' '}
            <Service
               image={serviceSecondOpinion}
               name="Second Opinion"
               description="We are happy to review existing portfolios for clients and their family, friends, and associates."
            />{' '}
            <Service
               image={serviceRiskManagement}
               name="Risk Management (Insurance)"
               description="There are multiple risks. These risks can either be assumed or transferred to a third party. Risks that could have a significant impact should be transferred."
            />
            <Service
               image={serviceSuccessionPlanning}
               name="Succession Planning"
               description="If you have an ownership interest in a business, it is important to ensure the continuity of the business in the possible event of a business owners premature death, disability, or retirement."
            />
            <Service
               image={serviceRetirementPlanning}
               name="Retirement Planning"
               description="There are two important phases: accumulation &amp; distribution. During the accumulation phase, it is important to maximize the available tax incentives. During the distribution phase, it is important to consider potential tax consequences and/or Medicare premiums."
            />
            <Service
               image={serviceEstateTaxPlanning}
               name="Estate Tax Planning"
               description="Various federal and State taxes may be applicable at death. In addition, there can be non-tax considerations for developing an Estate plan including the potential loss of government benefits by an heir; the need for guardianship provisions; the desire to protect a spouse and the goal of protecting an inheritance from creditor claims."
            />
         </div>
         <Link
            to="/contact?service=true"
            className="button button--secondary button--bordered button--large button--rounded button--has-icon button-contact"
            style={{ marginTop: '5rem' }}>
            Contact Us <span>&rarr;</span>
         </Link>
      </div>
   </Layout>
)

export default ServicesPage
