import React, { Component } from 'react'
import styles from './service.module.sass'

import { Link } from 'gatsby'
import Img from 'gatsby-image'

import Swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'

class Service extends Component {
   constructor(props) {
      super(props)

      this.setState = this.setState.bind(this)
   }

   popup(title, content, type) {
      Swal.fire(`${title}`, `${content}`, `${type}`)
   }

   render() {
      if (this.props.type === 'mini') {
         return (
            <>
               {/* <!-- START QUESTION MARK SVG --> */}
               <div hidden>
                  <svg
                     style={{
                        display: `none`,
                     }}
                     xmlns="http://www.w3.org/2000/svg">
                     <symbol
                        id="information"
                        height="100%"
                        width="100%"
                        viewBox="0 0 150 150"
                        xmlSpace="preserve">
                        <title>information</title>
                        <defs>
                           <style>{`.cls-1 { fill: #F3F0E7} }`}</style>
                        </defs>
                        <g id="Group_2" data-name="Group 2">
                           <g id="Group_1" data-name="Group 1">
                              <circle
                                 id="Ellipse_1"
                                 data-name="Ellipse 1"
                                 className="cls-1"
                                 cx="7.378"
                                 cy="7.378"
                                 r="7.378"
                                 transform="translate(67.622 103.482)"
                              />
                              <path
                                 id="Path_1"
                                 data-name="Path 1"
                                 className="cls-1"
                                 d="M75,0a75,75,0,1,0,75,75A74.959,74.959,0,0,0,75,0Zm0,138.281A63.281,63.281,0,1,1,138.281,75,63.247,63.247,0,0,1,75,138.281Z"
                              />
                              <path
                                 id="Path_2"
                                 data-name="Path 2"
                                 className="cls-1"
                                 d="M199.609,128.5A23.636,23.636,0,0,0,176,152.109a5.9,5.9,0,0,0,11.8,0,11.8,11.8,0,1,1,11.8,11.8,5.9,5.9,0,0,0-5.9,5.9v14.756a5.9,5.9,0,0,0,11.8,0v-9.6a23.611,23.611,0,0,0-5.9-46.472Z"
                                 transform="translate(-124.609 -91.045)"
                              />
                           </g>
                        </g>
                     </symbol>
                  </svg>
               </div>
               {/* <!-- END QUESTION MARK SVG --> */}

               <a href={this.props.link}>  <div className={styles.service__home}>
                  <Img
                     fluid={this.props.image}
                     className={styles.service__image}
                  />
                  <div className={styles.service__overlay}>
                     <div className={styles.content}>
                        <h3>{this.props.name}</h3>
                        {/* <svg className={styles.icon}>
                           <use xlinkHref="#information" />
                        </svg> */}
                        <p>{this.props.description}</p>
                        {/* <p>Learn More</p> */}
                     </div>
                  </div>
                  <div className={styles.service__caption}>
                     <p className={styles.service__name}>{this.props.name}</p>
                  </div>
               </div></a>
               {/* {typeof window !== `undefined` && (
                  Swal.fire('Hello world!')
               )} */}
            </>
         )
      } else {
         return (
            <div className={styles.service}>
               <div>
                  <img
                     src={this.props.image}
                     className={styles.service__image}
                     draggable="false"
                     alt=""
                  />
               </div>
               <div className={styles.service__caption}>
                  <h3 className={styles.service__name}>{this.props.name}</h3>
                  <p className={styles.service__description}>
                     {this.props.description}
                     {!!this.props.needsTaxDisclaimer && (
                        <Link to="/legal/disclaimers#tax">*</Link>
                     )}
                  </p>
                  <div className={styles.footer}>
                     {!!this.props.disclaimer && (
                        <p className={styles.disclaimer}>
                           {this.props.disclaimer}
                        </p>
                     )}
                     <Link
                        to={
                           '/new-client?service=' +
                           encodeURIComponent(this.props.name)
                        }
                        className="button button--secondary button--rounded button--small button--has-icon">
                        Let's Get Started{' '}
                        <FontAwesomeIcon icon={faLongArrowAltRight} />
                     </Link>
                  </div>
               </div>
            </div>
         )
      }
   }
}

export default Service
